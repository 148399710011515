import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRedirect, UserRedirect, AdminRedirect } from './services/middleware.service';

// Import Containers
import { HomeLayoutComponent } from './containers';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/',
  //   pathMatch: 'full',
  // },
 
  {
    path: '',
    component: HomeLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        loadChildren: './views/home/home.module#HomeModule'
      },
       
    ]
  
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
