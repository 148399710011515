import { Component, Input, OnInit } from '@angular/core';
import { navItems } from './../../_nav';
import * as $ from 'jquery';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  userprofile: any = false;
  intitals: string
    
  
  constructor(private router:Router) {
   
  }
  isActive(url) {
    
    return this.router.isActive(url, false);
 }
  ngOnInit() {
 
  }
  __showMenu(){
    $(".pro_dropdown_menu").toggleClass("open_dropdown");
  }
  _closeMenu(){
  
  } 

  _clicked(){
    let aria_expanded = $(document).find('.navbar-collapse').attr('aria-expanded')
    if(aria_expanded == 'true'){
      $(".navbar-toggle").trigger('click')
    }
  }
  

}