import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HttpModule }    from '@angular/http';
import { ErrorsComponent } from './views/errors/errors.component';
import { AlertService } from './services/alert.service';
import { DateFilter,MonthFilter, StringFilter } from './services/filter.service';
import { GlobalErrorHandlerService } from './services/route.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler,enableProdMode  } from '@angular/core';
import { LocationStrategy, HashLocationStrategy,CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { HomeLayoutComponent } from './containers';

import { environment } from '../environments/environment';
const APP_CONTAINERS = [
  HomeLayoutComponent
];
if (environment.production) {
  enableProdMode()
}


// Import routing module
import { AppRoutingModule } from './app.routing';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';



import { AlertComponent } from './views/alert/alert.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
   
    PerfectScrollbarModule,
    TabsModule.forRoot(),
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    AlertComponent,
  ],
  providers: [
  GlobalErrorHandlerService,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService 
  },
  
  AlertService,
 ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

