import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs/index';

import { Alert, AlertType } from './alert';
import * as $ from 'jquery';
import { Body } from '@angular/http/src/body';

@Injectable()
export class AlertService {
    dta = { type: null, message: null }
    private subject = new BehaviorSubject(this.dta);
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                  
                    this.clear();
                }
            }
        });
    }

    getAlert(){
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        console.log('alert', message)
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
       
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
       
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
        
    }

    alert(type: AlertType, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        let data = { type: type, message: message }
        this.subject.next(data);
        $('body').addClass('modal-open').css('padding-right', '17px')
        $('#sucess').addClass('show').show()
        this.clear()
       
    }

    clear() {
        this.subject.next(this.dta);
    }
}